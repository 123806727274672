import fetch from 'isomorphic-fetch';

import {ApolloClient, InMemoryCache, createHttpLink} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import jwtDecode from 'jwt-decode';
import {UMF_ADMIN_TOKEN} from '../constants/constants';

const httpLink = createHttpLink({
  uri: process.env.GATSBY_APP_SERVER,
  fetch,
  credentials: 'include',
});

const excludedPaths = ['/app/login'];

const authLink = setContext((_, {headers}) => {
  // return the headers to the context so httpLink can read them
  const token = localStorage.getItem(UMF_ADMIN_TOKEN);

  const currentUrl = window.location.pathname;

  // Function to check if the current URL matches any of the excluded paths
  const isExcludedPath = (currentUrl: string, excludedPaths: string[]) => {
    return excludedPaths.some(path => currentUrl === path);
  };

  const isNotExcludedPath = !isExcludedPath(currentUrl, excludedPaths);

  if (token && isTokenExpired(token)) {
    // If the token is expired, remove it from storage
    localStorage.removeItem(UMF_ADMIN_TOKEN);
    return {
      headers,
    };
  }

  return {
    headers: {
      ...headers,
      authorization: token && isNotExcludedPath ? `Bearer ${token}` : '',
    },
  };
});

function isTokenExpired(token: string) {
  try {
    const decoded: any = jwtDecode(token);
    const currentTime = Date.now() / 1000;

    if (decoded?.exp && decoded?.exp < currentTime) {
      // Token is expired
      return true;
    } else {
      // Token is not expired
      return false;
    }
  } catch (error) {
    return true; // If the token cannot be decoded, consider it expired
  }
}

const cacheOptions = {
  typePolicies: {},
};

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});

export default client;
